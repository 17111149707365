/* Achievements Grid */
.achievements-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Achievement Card Style */
  .achievement-card {
    background-color: #f1f1f1; /* Or any color fitting your theme */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
  }
  
  .achievement-card:hover {
    transform: translateY(-10px);
  }
  
  /* Achievement Icon (Placeholder - You can replace with your icon) */
  .achievement-icon {
    width: 60px;
    height: 60px;
    background-color: #61DAFB; /* React blue, replace with an appropriate color or add an icon */
    border-radius: 50%;
    margin: 0 auto 20px auto;
  }
  
  /* Responsive Design for smaller screens */
  @media (max-width: 768px) {
    .achievement-card {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  